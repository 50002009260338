<template>
  <main class="support-page">
    <section class="h-100">
      <div class="container py-5 h-100">
        <mega-card class="rounded h-100" style="overflow: hidden">
          <div class="row chat-card h-100">
            <div class="col-4 pr-0 dialogs h-100">
              <input
                type="search"
                :placeholder="$t('search')"
                v-model="searchChat"
                class="chat-search"
              />
              <div
                class="new-messages-tip"
                :class="{ 'new-messages-tip-shown': showNewChatsTip }"
                @click="requestNewChats"
              >
                {{ $t("unread_chats") }}
                <!-- <span class="new-messages-count">{{ unreadChats }}</span> -->
              </div>
              <mega-loading
                class="d-flex h-100 w-100 align-items-center justify-content-center"
                v-if="chats.length === 0"
              />
              <div v-else class="chats-pages">
                <div
                  class="chats-page"
                  v-for="(page, pageIndex) in chatPages"
                  :key="'chat-page-' + pageIndex"
                >
                  <div class="chat-page-delimiter" v-if="pageIndex > 0">
                    <div class="chat-page-delimiter-line" />
                    <div class="chat-page-delimiter-text">
                      {{ pageIndex + 1 }} {{ $t("page") }}
                    </div>
                  </div>

                  <transition-group name="list-complete" tag="div">
                    <user
                      :avatar="chat.avatar"
                      :name="chat.name"
                      :user-id="chat.username"
                      :active="activeChat === chat.id"
                      :unreadMessages="chat.unread_count"
                      v-for="chat in page"
                      :lastMessage="[
                        chat.last_message.text,
                        chat.last_message['author_id']
                      ]"
                      :key="'chat-' + chat.id"
                      @click="requestChatHistory(chat)"
                      :class="{ 'unread-chat': !!chat.unread_count }"
                    />
                  </transition-group>
                </div>
                <a
                  class="chat-more"
                  v-if="!hideLoadLink && searchChat.length === 0"
                  @click="requestChats(chatsLimit, chatsOffset)"
                  >{{ $t("load_more") }}</a
                >
              </div>
              <div
                v-if="chats.length > 0"
                class="text-muted chat-settings-bar d-flex flex-column justify-content-end"
              >
                <span class="mx-3 mt-1 mb-0 text-muted">
                  {{ $t("chats_per_page") }}
                </span>
                <select
                  v-model="chatsLimit"
                  @change="reloadChats"
                  class="p-1 m-3 mt-1 text-muted"
                >
                  <option disabled selected>
                    {{ $t("chats_per_page") }}
                  </option>
                  <option :value="10"> 10 </option>
                  <option :value="50"> 50 </option>
                  <option :value="100"> 100 </option>
                  <option :value="200"> 200 </option>
                </select>
              </div>
            </div>
            <div class="col-8 pl-0">
              <div class="chat-viewport h-100" v-if="activeChat">
                <div class="chat-header">
                  <router-link
                    v-if="partner.username"
                    :to="{ name: 'User', params: { id: partner.partner_id } }"
                    style="color: #000"
                    class="w-100"
                  >
                    <user
                      class="p-1"
                      :avatar="partner.avatar"
                      :name="partner.name"
                      :user-id="partner.username"
                      :mode="'chat'"
                    />
                  </router-link>
                </div>
                <div class="chat-history" ref="history">
                  <mega-loading
                    :distance="0"
                    :direction="'top'"
                    v-if="messages.length"
                    @infinite="requestTopHistory(partner, $event)"
                    :identifier="partner.id"
                  ></mega-loading>
                  <chat-messages
                    v-if="messages"
                    :chat="messages"
                    class="p-3"
                    ref="messages"
                    :chatObj="partner"
                    @readMessage="readMessage"
                  />
                </div>
                <div class="chat-controls">
                  <div
                    class="chat-controls d-flex align-items-center justify-content-center pr-3"
                  >
                    <textarea
                      ref="messageInput"
                      @keypress.shift.enter.exact.prevent="sendMessage"
                      v-model="message"
                      :placeholder="$t('send_message')"
                      type="text"
                      class="chat-input w-100"
                    />
                    <div class="chat-send-btn" @click="sendMessage">
                      <i class="i-send"></i>
                    </div>
                  </div>
                  <small class="chat-send-tip text-muted pr-3 pl-3">
                    shift + enter чтобы отправить
                  </small>
                </div>
              </div>
              <div
                v-else
                class="w-100 h-100 d-flex align-items-center justify-content-center"
              >
                <span class="text-muted"> {{ $t("select_chat") }} </span>
              </div>
            </div>
          </div>
        </mega-card>
      </div>
    </section>
  </main>
</template>

<style scoped>
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-complete-leave-active {
  position: absolute;
}

.chat-search {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 0;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 4px;
  margin-bottom: 10px;
  position: relative;
  z-index: 9;
  background: #f0f1f2;
  padding: 15px;
}
.unread-chat {
  background-color: #ffc8001f;
}
.new-messages-tip {
  height: 50px;
  text-align: center;
  background-color: #0077dc;
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
  opacity: 0;
  transform: scaleY(0);
  transition: 0.3s ease;
  transition-property: opacity, margin, transform;
}
.new-messages-tip-shown {
  margin-top: 0px;
  opacity: 1;
  transform: scaleY(1);
}
.new-messages-count {
  min-width: 20px;
  height: 20px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-left: 5px;
  line-height: 1;
}
.support-page {
  height: calc(100vh - 70px - 54px);
}
.dialogs {
  height: calc(100vh - 70px - 54px - 95px);
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chats-pages {
  height: 100%;
  overflow: auto;
  overflow-x: auto;
}
.chat-settings-bar {
  font-size: 0.8rem;
  box-shadow: 0 -8px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.chat-more {
  padding: 1rem;
  display: block;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
}
.chat-page-delimiter {
  width: 100%;
  margin: 25px 0;
  font-size: 0.8rem;
  background: #fff;
  position: relative;
  text-align: center;
  border-radius: 10px;
}
.chat-page-delimiter-line {
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.chat-page-delimiter-text {
  display: inline;
  background: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 10;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
}
.chat-viewport {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: calc(100vh - 70px - 54px - 95px);
}
.chat-header {
  max-width: 100%;
  background-color: #fff;
  z-index: 99;
  overflow: hidden;
}
.chat-controls {
  width: 100%;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.chat-send-tip {
  font-size: 0.7rem;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.chat-history {
  overflow-y: auto;
}
.chat-input {
  padding: 1.3rem;
  font-size: 0.85rem;
  padding-bottom: 0;
  border: none;
  resize: none;
  height: 60px;
}
.chat-input::placeholder {
  opacity: 0.5;
}
.chat-send-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1.3rem;
  border-radius: 100%;
  color: #fff;
  background-color: #0078dc;
  cursor: pointer;
}
.chat-send-btn .i-send {
  transform: translate(-2px, 1px);
}
</style>

<script>
import User from "./components/user";
import ChatMessages from "./components/chat-messages";

const messageServerUrl = "arround-msg.gora.studio/v2/ws";

export default {
  components: {
    User,
    ChatMessages
  },

  data() {
    return {
      activeChat: null,
      messages: [],
      partner: {},
      message: "",
      connection: null,
      chats: [],
      chatsOffset: 0,
      chatsLimit: 100,
      hideLoadLink: false,
      unreadMessages: this.$store.state.unreadMessages,
      searchChat: "",
      showNewChatsTip: false,
      firstChat: {},

      // While backend isnt perfect
      // Backend has no event for new chats
      __FLAG_SELECTED_CHAT: false
    };
  },

  computed: {
    mediaMessages() {},
    // unreadChats() {
    //   return new Set(this.unreadMessages).size;
    // },
    chatPagesCount() {
      return this.chatPages.length;
    },
    searchedChats() {
      return this.chats.filter(
        chat =>
          chat.name.toLowerCase().includes(this.searchChat.toLowerCase()) ||
          chat.username.toLowerCase().includes(this.searchChat.toLowerCase())
      );
    },
    chatPages() {
      const pages = Math.ceil(this.chats.length / this.chatsLimit);
      let curPage = 1;

      // pretty loop
      return new Array(this.searchChat.length > 0 ? 1 : pages).fill().map(p => {
        const sliceStart = (curPage - 1) * this.chatsLimit;
        const sliceEnd = curPage * this.chatsLimit;
        const page = this.searchedChats.slice(sliceStart, sliceEnd);

        curPage++;

        return page;
      });
    }
  },

  async mounted() {
    localStorage.setItem("chats-per-page-limit", this.chatsLimit);
    this.$navbar.name = this.$t("support");

    await this.initMessagesStream();
    this._restoreAndUpdateChatsLimit();
    this.requestChats(this.chatsLimit);
  },

  methods: {
    readMessage(message) {
      console.log(message);
      this.$api.msg.put("/admin/support/chat/read", {
        chat_id: message.chat_id,
        message_id: message.id
      });
    },
    requestTopHistory(chat, $event) {
      this.$api.msg
        .get("/admin/support/message/list", {
          params: {
            chat_id: chat.id,
            message_id: this.messages.length
              ? this.messages[this.messages.length - 1].id
              : 0,
            back_limit: 25
          }
        })
        .then(res => {
          if (res.data.length) {
            console.log("Data", res.data);
            this.messages.push(...res.data);
            $event.loaded();
          } else {
            $event.complete();
          }
          // this.$store.commit("clearUnreadMessages", chat.id);
          // this.unreadMessages = this.$store.state.unreadMessages;

          // this.unreadMessages = this.unreadMessages.filter(
          //   unread_id => unread_id != chat.id
          // );
        });
    },
    requestBottomHistory() {
      console.log("Bottom");
    },
    //init websocket and make handlers
    async initMessagesStream() {
      return new Promise(resolve => {
        if (this.$store.state.msgSocket) {
          this.connection = this.$store.state.msgSocket;
          this.connection.addEventListener("open", () => {
            resolve();
          });

          this.connection.addEventListener("close", () => {
            console.log("RECONNECT");
            this.$store.dispatch("openMsgSocket");
            this.initMessagesStream();
          });
          this.connection.addEventListener("message", this.handleResponse);

          resolve();
        } else {
          this.$store.dispatch("openMsgSocket").then(() => {
            this.initMessagesStream();
          });
        }
      });
    },

    handleResponse(response) {
      if (!response.data) return;

      const handlers = {
        "chat.all": this.loadChats,
        "chat.one": this.loadChatHistory,
        "message.send": this.newMessage,
        message_create: this.messageCreate,
        message_delete: this.messageDelete,
        message_update: this.messageUpdate,
        chat_update: this.chatUpdate,
        chat_create: this.chatCreate
      };

      const action = JSON.parse(response.data);
      const action_name = Object.keys(action)[0];
      // console.log(action);

      // if (!handlers[action]) return false;

      // console.log(handlers[Object.keys(action)[0]]);

      if (this.$route.name == "Support") {
        setTimeout(() => handlers[action_name](action));
      }
    },

    //handler for message.create type
    messageCreate(data) {
      data = data.message_create;
      console.log("Message:", data);

      if (data.chat_id === this.activeChat) {
        this.messages.unshift(data);
        this.readMessage(data);
        this.$refs.history.scroll({
          top: this.$refs.history.scrollHeight * 2
        });
        this.$refs.messageInput.focus();
      }
      // else {
      //   this.$store.commit("pushUnreadMessage", data.chat_id);
      //   this.unreadMessages.push(data.chat_id);
      // }
      // this.updateChatHistory(data);
    },

    messageUpdate(data) {
      data = data.message_update;

      if (data.chat_id == this.activeChat) {
        let updatedIndex = this.messages.findIndex(message => {
          return message.id === data.id;
        });
        this.messages.splice(updatedIndex, 1, data);
      }
    },

    messageDelete(data) {
      data = data.message_delete;
      console.log(data);

      if (data.chat_id === this.activeChat) {
        const index = this.messages.findIndex(
          message => message.id === data.message_id
        );
        this.messages.splice(index, 1);
      }
    },

    //handler for chat.update type
    chatUpdate(data) {
      data = data.chat_update;
      const updated_chat_index = this.chats.findIndex(
        chat => chat.id === data.id
      );

      this.chats.splice(updated_chat_index, 1);
      this.chats.push(data);
      this.chats.sort((a, b) => {
        return (
          new Date(b.last_message.created_at) -
          new Date(a.last_message.created_at)
        );
      });
    },

    //handler for chat.create type
    chatCreate(data) {
      this.chats.push(data.chat_create);
      this.chats.sort((a, b) => {
        return (
          new Date(b.last_message.created_at) -
          new Date(a.last_message.created_at)
        );
      });
    },

    //handler for chat.all type response
    loadChats(newChats) {
      let ONCE_FLAG = false;
      if (!ONCE_FLAG) {
        ONCE_FLAG = true;
        this.requestChatHistory(newChats[0].id);
      }
      const chatId = Number(this.$route.params.chat);

      if (chatId) {
        if (this.chats.findIndex(c => c.id === chatId))
          this.requestChatHistory(chatId);

        this.activeChat = chatId;
      }

      const chats = [...this.chats, ...newChats];
      // this.chats = Array.from(new Set(chats));
      this.chats = chats.filter(chat => chat.messages.length > 0);
      this.chatsOffset += this.chatsLimit;

      if (newChats.length < this.chatsLimit) this.hideLoadLink = true;
    },

    //handler for chat.one type response
    async loadChatHistory(data) {
      if (this.activeChat != data.id) {
        return;
      }
      const { id, messages, partner } = data;
      const chatId = Number(this.$route.params.chat);

      // goto line 15 for more information
      if (true === this.__FLAG_SELECTED_CHAT) {
        this.preloadMedia(messages).then(media => (this.messages = media));

        this.partner = partner;
      } else {
        this.showNewChatsTip = false;
        this.chats.unshift(data);
      }

      // this.unreadMessages = this.unreadMessages.filter(unread => unread !== id);

      this.$nextTick(() => {});

      setTimeout(() => {
        this.$refs.history.scroll({ top: this.$refs.history.scrollHeight * 2 });
        this.$refs.messageInput.focus();
      }, 200);
    },

    requestChatHistory(chat) {
      this.messages = [];
      // if (this.partner.id === chat.id) return;

      window.history.pushState({ chat: chat.id }, "", `/support/${chat.id}`);

      // While backend isnt perfect
      // Backend has no event for new chats
      this.__FLAG_SELECTED_CHAT = true;

      this.activeChat = chat.id;
      this.partner = chat;
      this.$api.msg
        .get("/admin/support/message/list", {
          params: {
            chat_id: chat.id,
            back_limit: 10
          }
        })
        .then(res => {
          this.messages = res.data;
          // this.$store.commit("clearUnreadMessages", chat.id);
          // this.unreadMessages = this.$store.state.unreadMessages;

          // this.unreadMessages = this.unreadMessages.filter(
          //   unread_id => unread_id != chat.id
          // );
        });

      setTimeout(() => {
        this.$refs.history.scroll({ top: this.$refs.history.scrollHeight * 2 });
        this.$refs.messageInput.focus();
      }, 200);
    },

    reloadChats() {
      const limit = this.chatPagesCount * this.chatsLimit;

      localStorage.setItem("chats-per-page-limit", this.chatsLimit);

      this.chats = [];
      this.chatsOffset = 0;
      this.requestChats(limit, 0);
      this.chatsOffset = limit;
    },

    sendMessage(isReceived) {
      const text = this.message.trim();

      if (text.length === 0) return;

      this.$api.msg.post("/admin/support/message/message", {
        chat_id: this.activeChat,
        text
      });

      // this.sendCommand("message.send", {
      //   partner_id: this.partner.id,
      //   text
      // });

      this.message = "";
    },

    isNewMessage(messageId) {
      return !this.messages.find(m => m.id === messageId);
    },

    async preloadMedia(messages) {
      return new Promise(resolve => {
        const promises = messages.map(message => {
          message.message_objects = message.message_objects.map(
            ({ type, object_id }) => {
              const fetchUrl = type == 1 ? "/model" : "/round";
              const objectKey = type == 1 ? "idt_model" : "idt_round";
              const result = this.$api.v2(fetchUrl, {
                params: { [objectKey]: object_id }
              });

              return result || [];
            }
          );
          return message;
        });

        const res = promises.map(p => {
          if (p.message_objects.length === 0) return p;

          Promise.all(p.message_objects).then(
            objs => (p.message_objects = objs.map(o => (o = o.data)))
          );

          return p;
        });
        resolve(res);
      });
    },

    async updateChatHistory(data) {
      const { messages, partner, id } = { ...data };

      this.chats.find(i => i.id === id).messages[0].text = messages[0].text;
      this.chats.find(i => i.id === id).messages[0]["user_id"] =
        messages[0]["user_id"];

      if (this.chats[0].id != id) {
        let updateIndex = this.chats.findIndex(item => item.id == data.id);
        if (updateIndex) {
          let updatedChat = this.chats[updateIndex];
          this.chats.splice(updateIndex, 1);
          this.chats.unshift(updatedChat);
        } else {
          this.chats.unshift(data);
        }
      }
      if (this.partner.id !== partner.id) return false;

      this.partner = partner;

      if (this.partner) {
        if (this.partner.id === partner.id) {
          const newMessages = messages.filter(m => {
            return !this.messages.find(v => v.id === m.id);
          });
          this.preloadMedia(newMessages).then(media => {
            this.messages = [...media, ...this.messages];
          });
        } else {
          this.messages = messages;
        }

        setTimeout(() => {
          this.$refs.history.scroll({
            top: this.$refs.history.scrollHeight * 2
          });
          this.$refs.messageInput.focus();
        });
      }
    },

    requestNewChats() {
      const limit = this.chats.length;
      const unreadChats = Array.from(new Set(this.unreadMessages));

      /*/
       |  Next chat, loaded via this method
       |  Will be handled as new chat
       |  And will be pushed at the first of this.chats
       |  This flags indicated that new chats
       |  loaded via chat.one will not be selected in UI
       |  as active chat
       |  This need for keep selected chat
       |
      /*/ this.__FLAG_SELECTED_CHAT = false;

      unreadChats.forEach(chat => {
        // if chat exist on page
        if (this.chats.findIndex(c => c.id === chat) > -1) return;
        this.sendCommand("chat.one", {
          chat_id: chat,
          m_limit: 0,
          m_offset: 0
        });
      });
    },

    requestChats(limit) {
      this.$api.msg
        .get("/admin/support/chat/list", {
          params: {
            limit,
            types: "support"
          }
        })
        .then(res => {
          this.chats = res.data.filter(chat => chat.last_message);
        });
    },

    _restoreAndUpdateChatsLimit() {
      let limit = localStorage.getItem("chats-per-page-limit");

      if (!limit) localStorage.setItem("chats-per-page-limit", this.chatsLimit);

      this.chatsLimit = Number(limit);
    }
  }
};
</script>
