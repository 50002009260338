var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "support-page" }, [
    _c("section", { staticClass: "h-100" }, [
      _c(
        "div",
        { staticClass: "container py-5 h-100" },
        [
          _c(
            "mega-card",
            {
              staticClass: "rounded h-100",
              staticStyle: { overflow: "hidden" }
            },
            [
              _c("div", { staticClass: "row chat-card h-100" }, [
                _c(
                  "div",
                  { staticClass: "col-4 pr-0 dialogs h-100" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchChat,
                          expression: "searchChat"
                        }
                      ],
                      staticClass: "chat-search",
                      attrs: { type: "search", placeholder: _vm.$t("search") },
                      domProps: { value: _vm.searchChat },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.searchChat = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "new-messages-tip",
                        class: {
                          "new-messages-tip-shown": _vm.showNewChatsTip
                        },
                        on: { click: _vm.requestNewChats }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("unread_chats")) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.chats.length === 0
                      ? _c("mega-loading", {
                          staticClass:
                            "d-flex h-100 w-100 align-items-center justify-content-center"
                        })
                      : _c(
                          "div",
                          { staticClass: "chats-pages" },
                          [
                            _vm._l(_vm.chatPages, function(page, pageIndex) {
                              return _c(
                                "div",
                                {
                                  key: "chat-page-" + pageIndex,
                                  staticClass: "chats-page"
                                },
                                [
                                  pageIndex > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "chat-page-delimiter" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "chat-page-delimiter-line"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "chat-page-delimiter-text"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(pageIndex + 1) +
                                                  " " +
                                                  _vm._s(_vm.$t("page")) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "transition-group",
                                    {
                                      attrs: {
                                        name: "list-complete",
                                        tag: "div"
                                      }
                                    },
                                    _vm._l(page, function(chat) {
                                      return _c("user", {
                                        key: "chat-" + chat.id,
                                        class: {
                                          "unread-chat": !!chat.unread_count
                                        },
                                        attrs: {
                                          avatar: chat.avatar,
                                          name: chat.name,
                                          "user-id": chat.username,
                                          active: _vm.activeChat === chat.id,
                                          unreadMessages: chat.unread_count,
                                          lastMessage: [
                                            chat.last_message.text,
                                            chat.last_message["author_id"]
                                          ]
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.requestChatHistory(chat)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            !_vm.hideLoadLink && _vm.searchChat.length === 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "chat-more",
                                    on: {
                                      click: function($event) {
                                        return _vm.requestChats(
                                          _vm.chatsLimit,
                                          _vm.chatsOffset
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("load_more")))]
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                    _vm._v(" "),
                    _vm.chats.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-muted chat-settings-bar d-flex flex-column justify-content-end"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mx-3 mt-1 mb-0 text-muted" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("chats_per_page")) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.chatsLimit,
                                    expression: "chatsLimit"
                                  }
                                ],
                                staticClass: "p-1 m-3 mt-1 text-muted",
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.chatsLimit = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.reloadChats
                                  ]
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { disabled: "", selected: "" } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("chats_per_page")) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("option", { domProps: { value: 10 } }, [
                                  _vm._v(" 10 ")
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: 50 } }, [
                                  _vm._v(" 50 ")
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: 100 } }, [
                                  _vm._v(" 100 ")
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: 200 } }, [
                                  _vm._v(" 200 ")
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-8 pl-0" }, [
                  _vm.activeChat
                    ? _c("div", { staticClass: "chat-viewport h-100" }, [
                        _c(
                          "div",
                          { staticClass: "chat-header" },
                          [
                            _vm.partner.username
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "w-100",
                                    staticStyle: { color: "#000" },
                                    attrs: {
                                      to: {
                                        name: "User",
                                        params: { id: _vm.partner.partner_id }
                                      }
                                    }
                                  },
                                  [
                                    _c("user", {
                                      staticClass: "p-1",
                                      attrs: {
                                        avatar: _vm.partner.avatar,
                                        name: _vm.partner.name,
                                        "user-id": _vm.partner.username,
                                        mode: "chat"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { ref: "history", staticClass: "chat-history" },
                          [
                            _vm.messages.length
                              ? _c("mega-loading", {
                                  attrs: {
                                    distance: 0,
                                    direction: "top",
                                    identifier: _vm.partner.id
                                  },
                                  on: {
                                    infinite: function($event) {
                                      return _vm.requestTopHistory(
                                        _vm.partner,
                                        $event
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.messages
                              ? _c("chat-messages", {
                                  ref: "messages",
                                  staticClass: "p-3",
                                  attrs: {
                                    chat: _vm.messages,
                                    chatObj: _vm.partner
                                  },
                                  on: { readMessage: _vm.readMessage }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "chat-controls" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "chat-controls d-flex align-items-center justify-content-center pr-3"
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.message,
                                    expression: "message"
                                  }
                                ],
                                ref: "messageInput",
                                staticClass: "chat-input w-100",
                                attrs: {
                                  placeholder: _vm.$t("send_message"),
                                  type: "text"
                                },
                                domProps: { value: _vm.message },
                                on: {
                                  keypress: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    if (!$event.shiftKey) return null
                                    if (
                                      $event.ctrlKey ||
                                      $event.altKey ||
                                      $event.metaKey
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.sendMessage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.message = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "chat-send-btn",
                                  on: { click: _vm.sendMessage }
                                },
                                [_c("i", { staticClass: "i-send" })]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "small",
                            {
                              staticClass: "chat-send-tip text-muted pr-3 pl-3"
                            },
                            [
                              _vm._v(
                                "\n                  shift + enter чтобы отправить\n                "
                              )
                            ]
                          )
                        ])
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "w-100 h-100 d-flex align-items-center justify-content-center"
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(" " + _vm._s(_vm.$t("select_chat")) + " ")
                          ])
                        ]
                      )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }